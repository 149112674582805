import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Link} from '@remix-run/react';

import type {Product} from '@shopify/hydrogen-react/storefront-api-types';
import {LIMITED_EDITION_TAG} from '~/consts';

import {Image, MediaFile, Money} from '@shopify/hydrogen';

interface ProductCardProps {
  product: Product;
  square?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const BasicCard = ({
  square = true,
  className,
  product,
  children,
}: ProductCardProps) => {
  const limited = product.tags?.includes(LIMITED_EDITION_TAG);
  const {price, compareAtPrice} = product.variants?.nodes[0] || {};
  return (
    <Link
      to={`/products/${product.handle}`}
      className={twMerge(
        'group flex flex-col h-max gap-3 md:gap-6 text-night-1100 hover:text-primary',
        className,
      )}
    >
      <div
        className={twMerge(
          ' overflow-hidden w-full bg-honey-400 relative flex items-center jsutify-center rounded-2xl',
          square ? 'aspect-square' : 'aspect-[3/2]',
        )}
      >
        {limited && (
          <div className="absolute top-3 left-3 md:top-6 md:left-6 rounded-full h-6 md:h-8 flex items-center px-2 md:px-4 text-xs md:text-sm text-ruby border border-ruby font-medium ">
            Limited <span className="hidden md:inline-block">Edition</span>
          </div>
        )}
        {children}

        {!!product.variants.nodes[0]?.image && (
          <Image
            data={product.variants.nodes[0]?.image}
            alt={product.title}
            sizes="100%"
            className="group-hover:scale-[1.2] h-full w-full object-cover transition-transform transform duration-[.5s] ease-in-out"
          />
        )}
      </div>
      <div className="w-full flex flex-col">
        <div className="flex items-center justify-between text-night-600">
          <p className="text-xs">{product.productType}</p>
          <p className="text-xs">
            <Money withoutTrailingZeros data={price} />
          </p>
        </div>
        <h1 className="text-lg md:text-2xl font-medium leading-[120%] sm:leading-[160%] transition-colors">
          {product.title}
        </h1>
      </div>
    </Link>
  );
};

export default BasicCard;
